import { render, staticRenderFns } from "./VerifyAccount.vue?vue&type=template&id=782965fe&scoped=true"
import script from "./VerifyAccount.vue?vue&type=script&lang=js"
export * from "./VerifyAccount.vue?vue&type=script&lang=js"
import style0 from "./VerifyAccount.vue?vue&type=style&index=0&id=782965fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782965fe",
  null
  
)

export default component.exports