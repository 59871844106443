<template>
  <div class="verify-container">
    <div class="container">
      <p>Attempting to verify account...</p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "VerifyAccount",

  data() {
    return {
      uid: '',
      token: '',
      message: ''
    }
  },

  created() {
    this.uid = this.$route.params.uid;
    this.token = this.$route.params.token;

    const uid = this.uid;
    const token = this.token;

    if (uid && token) {
      this.verifyEmail({uid, token}).then(response => {
        this.$store.dispatch('notifications/pushNotification', {message: "Successfully confirmed your account! You can login now.", type: "green"});

        this.$tracking.trackEvent('verifiedAccount');

        this.$router.push('/login');
      })
      .catch(err => {
        this.$store.dispatch('notifications/pushNotification', {message: err.response.data['non_field_errors'][0], type: "danger"});
        this.$router.push('/login');
      });
    }
  },

  methods: {
    ...mapActions('accounts', ['verifyEmail']),
  }

}
</script>

<style scoped>
.verify-container {
  position: fixed;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
}
</style>
